.artGallery {
  text-align: center;
  font-size: 48px;
  font-family: "Poppins", cursive !important;
  position: relative;
  font-weight: 500;
  top: 3rem;
  color: #ffb000;
}

#gallery {
}
@media (max-width: 800px) {
  .artGallery {
    font-size: 24px;
    margin-bottom: 5rem;
  }
  .part3__bg {
    /* height: 50vh; */
  }
}
.part3__bg {
  /* background-image: url(../Section\ 3\ -\ Gallery.png); */
  background-color: #02080d;
  background-size: cover;
}

.flip-card {
  width: "25%" !important;
  height: "40rem" !important;
}
@keyframes spin {
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
